const startHour = 9;
const endHour = 18;
const timeOptions = [];

const currentTime = new Date();

const currentHour = currentTime.getHours();
const currentMinutes = currentTime.getMinutes();

// Booking should be 1 hour ahead
let allowedHour = currentHour + 1;
let allowedMinutes = currentMinutes;

for (let hour = startHour; hour <= endHour; hour++) {
  for (let minute = 0; minute <= 30; minute += 30) {
    if (hour === startHour && minute === 0) continue; // Skip 9:00
    if (hour === endHour) continue; // Skip 17:30

    // Check if the time slot is at least 1 hour ahead of current time
    if (
      hour > allowedHour ||
      (hour === allowedHour && minute >= allowedMinutes)
    ) {
      const timeString = `${String(hour).padStart(2, "0")}:${String(
        minute
      ).padStart(2, "0")}`;
      timeOptions.push(timeString);
    }
  }
}

export default timeOptions;
