import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Button,
  TextField,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import validate from "validate.js";
import { SectionHeader } from "components/molecules";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { HOSTNAME_LIVE } from "utils/constants";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import timeOptions from "../HeroBookForm/timeOptions";

const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    color: "black",
    position: "relative",
  },
  submitButton: {
    borderRadius: "4px",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px",
      margin: "auto 16px",
      height: "56px",
    },
    [theme.breakpoints.up("md")]: {
      margin: 0,
      width: "328px",
    },
  },
  alertBox: {
    width: "395px",
    height: "50px",
    padding: "9px",
    position: "relative",
    top: 0,
    marginLeft: "auto",
    marginRight: "auto",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
  },
}));

const schema = {
  salutation: {
    presence: { allowEmpty: false, message: "is required" },
  },
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 300,
    },
  },
  phone_number: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  services: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const ContactForm = (): JSX.Element => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [formState, setFormState] = React.useState<FormStateProps>({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [holidays, setHolidays] = useState([]);
  const fetchHolidays = async () => {
    try {
      const res = await fetch(`${HOSTNAME_LIVE}/api/booking/holiday/`);
      const json = await res.json();
      const formattedHolidays = json.map((holiday) =>
        new Date(holiday.date).getTime()
      );
      setHolidays(formattedHolidays);
    } catch (error) {
      console.log(error?.toString());
    }
  };
  useEffect(() => {
    fetchHolidays();
  }, []);

  const disableWeekends = (date) =>
    date.getDay() === 0 ||
    date.getDay() === 6 ||
    holidays.includes(date.getTime());

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleChangeSalutation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    formState.values.salutation = event.target.value;
    setSalutation(event.target.value);
  };

  const handleChangeService = (event: React.ChangeEvent<HTMLInputElement>) => {
    formState.values.services = event.target.value;
    setService(event.target.value);
  };
  const handleChangeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTime(event.target.value);
  };

  const handleDateChange = (date: Date) => {
    setDate(date);
  };

  const [alertSuccess, setAlertSuccess] = React.useState(false);
  const [alertError, setAlertError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const errors = validate(formState.values, schema);
    if (errors) {
      // If there are errors, update the form state with the error messages
      setFormState((formState) => ({
        ...formState,
        isValid: false,
        errors: errors,
      }));
    } else {
      setAlertError(false);
      setErrorMessage("");
      let hours = null,
        dateObject = null;
      if (time) {
        hours = parseInt(time);
      }
      if (date) {
        dateObject = new Date(date);
        dateObject.setHours(hours);
        dateObject.setMinutes(0);
        dateObject = dateObject.toISOString();
      }
      axios
        .post(`${HOSTNAME_LIVE}/api/form/contact/`, {
          salutation: salutation,
          name: formState.values.name,
          company: formState.values.company,
          phone_number: formState.values.phone_number,
          email: formState.values.email,
          services: service,
          subject: formState.values.subject,
          message: formState.values.message,
          date: dateObject,
          attendees: formState.values.attendees,
        })
        .then(function(response) {
          if (response) {
            setAlertSuccess(true);
          } else {
            setAlertError(true);
          }
        })
        .catch(function(error) {
          setAlertError(true);
        });
    }
  };

  const hasError = (field: string) => {
    return formState.errors[field] !== undefined ? true : false;
  };

  const [salutation, setSalutation] = React.useState("");
  const [service, setService] = React.useState("");
  const [time, setTime] = React.useState("");
  const [date, setDate] = React.useState<Date>(null);
  const [state, setState] = React.useState({
    checked: true,
  });
  const [formateError, setFormateError] = useState(false);
  function phonenumber(e) {
    var phoneno = /^(\+?\d*)$/;
    let preval = e.target.value;
    if (phoneno.test(preval)) {
      setFormateError(false);
      return;
    } else {
      e.target.value = preval.substring(0, preval.length - 1);
      setFormateError(true);
    }
  }
  return (
    <div className={classes.root}>
      {alertError ? (
        <Alert
          onClose={() => {
            setAlertError(false);
          }}
          severity="error"
          className={classes.alertBox}
        >
          Error! {errorMessage ? errorMessage : "Something went wrong"}
        </Alert>
      ) : (
        <></>
      )}
      {alertSuccess ? (
        <Box display={"flex"} columnGap={"10px"}>
          <CheckCircleRoundedIcon color={"success"} fontSize={"large"} />
          <Typography variant={"h4"} align={"center"} color="textPrimary">
            {"Form has been submitted Successfully"}
          </Typography>
        </Box>
      ) : (
        <form name="contact-form" method="post" action="">
          <input type="hidden" name="form-name" value="contact-form" />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionHeader
                title={t("contactFormTitle")}
                subtitle={t("contactFormSubtitle")}
                titleVariant="h4"
                subtitleVariant="body1"
                subtitleColor="textPrimary"
                style={{ marginBottom: "1%" }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-select-salutation"
                select
                label={t("contactFormSalutation")}
                InputLabelProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                InputProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                value={salutation}
                onChange={handleChangeSalutation}
                variant="outlined"
                required
                fullWidth
                name="salutation"
                helperText={
                  hasError("salutation")
                    ? t("contactFormSalutationError")
                    : null
                }
                error={hasError("salutation")}
              >
                <MenuItem value="Mr." style={{ fontFamily: "Roboto" }}>
                  {t("contactFormSalutationMr")}
                </MenuItem>
                <MenuItem value="Mrs." style={{ fontFamily: "Roboto" }}>
                  {t("contactFormSalutationMrs")}
                </MenuItem>
                <MenuItem value="Ms." style={{ fontFamily: "Roboto" }}>
                  {t("contactFormSalutationMs")}
                </MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                placeholder={t("nameEgPlaceHolder")}
                label={t("contactFormName")}
                InputLabelProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                InputProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                variant="outlined"
                size="medium"
                name="name"
                required
                fullWidth
                //formState.errors.name[0]
                // helperText={hasError('name') ? t('tourBookingNameError') : null}
                // error={hasError('name')}
                onChange={handleChange}
                type="text"
                value={formState.values.name || ""}
                helperText={hasError("name") ? formState.errors.name[0] : null}
                error={hasError("name")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="Company"
                label={t("contactFormCompany")}
                InputLabelProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                InputProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                variant="outlined"
                size="medium"
                name="company"
                fullWidth
                onChange={handleChange}
                type="text"
                value={formState.values.company || ""}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="1234 5678"
                label={t("contactFormPhone")}
                InputLabelProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                InputProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                variant="outlined"
                size="medium"
                name="phone_number"
                required
                fullWidth
                onInput={(e) => {
                  phonenumber(e);
                }}
                helperText={
                  formateError === true
                    ? t("tourBookingFormPhoneFormateError")
                    : hasError("phone_number")
                    ? t("tourBookingphone_numberError")
                    : null
                }
                error={formateError === true ? true : hasError("phone_number")}
                onChange={handleChange}
                type="phone_number"
                value={formState.values.phone_number || ""}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="Email"
                label={t("contactFormEmail")}
                InputLabelProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                InputProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                variant="outlined"
                size="medium"
                name="email"
                required
                fullWidth
                // helperText={hasError('email') ? t('tourBookingemailError') : null}
                // error={hasError('email')}
                onChange={handleChange}
                type="email"
                value={formState.values.email || ""}
                helperText={
                  hasError("email") ? formState.errors.email[0] : null
                }
                error={hasError("email")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-select-service"
                select
                label={t("contactFormService")}
                InputLabelProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                InputProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                value={service}
                onChange={handleChangeService}
                variant="outlined"
                fullWidth
                name="services"
                required
                helperText={
                  hasError("services") ? t("contactBookingServiceError") : null
                }
                error={hasError("services")}
              >
                <MenuItem
                  value="Private Offices"
                  style={{ fontFamily: "Roboto" }}
                >
                  {t("contactFormServicePrivate")}
                </MenuItem>
                <MenuItem
                  value="Hot Desk / Dedicated Desk"
                  style={{ fontFamily: "Roboto" }}
                >
                  {t("contactFormServiceHotDesk")}
                </MenuItem>
                <MenuItem value="Meeting Room" style={{ fontFamily: "Roboto" }}>
                  {t("contactFormServiceMeetingRoom")}
                </MenuItem>
                <MenuItem
                  value="Event / Shooting Space"
                  style={{ fontFamily: "Roboto" }}
                >
                  {t("contactFormServiceEvent")}
                </MenuItem>
                <MenuItem value="Others" style={{ fontFamily: "Roboto" }}>
                  {t("contactFormServiceOthers")}
                </MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="Subject"
                label={t("contactFormSubject")}
                InputLabelProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                InputProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                variant="outlined"
                name="subject"
                fullWidth
                helperText={
                  hasError("subject") ? t("tourBookingSubjectError") : null
                }
                error={hasError("subject")}
                onChange={handleChange}
                value={formState.values.subject || ""}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.root}
                fullWidth
                name="time"
                id="time"
                select
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  },
                }}
                InputProps={{
                  style: {
                    fontFamily: "Roboto",
                    fontWeight: 400,
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontFamily: "Roboto",
                    fontWeight: 400,
                  },
                }}
                label={t("tourBookingFormTime")}
                size="medium"
                onChange={handleChangeTime}
              >
                {timeOptions.map((i) => (
                  <MenuItem value={i} key={i}>
                    {`${String(i).padStart(2, "0")}`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="10"
                label={t("tourBookingFormPax")}
                InputLabelProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                InputProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                variant="outlined"
                name="attendees"
                fullWidth
                onChange={handleChange}
                value={formState.values.attendees || ""}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  className={classes.root}
                  minDate={new Date()}
                  value={date}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      id="date"
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          fontFamily: "Roboto",
                          fontWeight: 400,
                        },
                      }}
                    />
                  )}
                  label={t("tourBookingFormDate")}
                  InputAdornmentProps={{ position: "end" }}
                  inputFormat="MM/dd/yyyy"
                  InputProps={{
                    style: {
                      fontFamily: "Roboto",
                      fontWeight: 400,
                    },
                  }}
                  OpenPickerButtonProps={{
                    style: { color: "#0f1f38" },
                  }}
                  views={["year", "month", "day"]}
                  shouldDisableDate={disableWeekends}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <TextField
                placeholder="Hello! ..."
                label={t("contactFormMessage")}
                InputLabelProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                InputProps={{
                  style: { fontFamily: "Roboto", fontWeight: 400 },
                }}
                variant="outlined"
                name="message"
                fullWidth
                helperText={
                  hasError("message") ? t("tourBookingMessageError") : null
                }
                error={hasError("message")}
                onChange={handleChange}
                multiline
                minRows={2}
                value={formState.values.message || ""}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" color="primary">
                By clicking the Submit button it means that you agree to the{" "}
                <a href="./terms-and-conditions" style={{ color: "blue" }}>
                  Terms and Conditions
                </a>{" "}
                and have read and understood the{" "}
                <a href="#" style={{ color: "blue" }}>
                  Privacy Policy
                </a>
                .
                <br />
                {i18n.language !== "en" && <p>{t("contactFormDisclaimer")}</p>}
              </Typography>
            </Grid>

            <Grid item md={4} />
            <Grid item xs={12} md={4}>
              <div style={{ textAlign: "center" }}>
                <Button
                  size={isMd ? "medium" : "large"}
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={!formState.isValid && !state.checked}
                  className={classes.submitButton}
                  onClick={handleSubmit}
                >
                  {t("contactFormSubmitButton")}
                </Button>
              </div>
            </Grid>
            <Grid item md={4} />
          </Grid>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
